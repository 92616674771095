import React from 'react'
import styled from 'styled-components'

import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg'

const Wrapper = styled.div`
  text-align: center;
  margin: ${({ theme }) => theme.spacers.large};
`

function VerifyEmail() {
  return (
    <Wrapper>
      <LoadingImage></LoadingImage>
      <h2>เรากำลังตรวจสอบการยืนยันอีเมล์ของคุณ...</h2>
    </Wrapper>
  )
}

export default VerifyEmail
