import React, { useCallback, useEffect } from 'react'
import { getFirebaseAuth } from '@babelcoder/gatsby-plugin-firebase'
import { navigate } from 'gatsby'

import {
  useGlobalDispatch,
  useGlobalState,
} from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'
import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer'
import VerifyEmail from '@components/auth/VerifyEmail'
import authErrors from '@utils/auth-errors'

function VerifyEmailPage({ location: { state } }) {
  const { user } = useGlobalState()
  const dispatch = useGlobalDispatch()
  const { code } = state || {}

  const verifyEmail = useCallback(async () => {
    try {
      const firebaseAuth = await getFirebaseAuth()

      await firebaseAuth.applyActionCode(code)

      if (user) await firebaseAuth.currentUser.reload()

      dispatch({ type: actions.clearFlashMessage })
      navigate('/auth/complete', {
        replace: true,
        state: { type: 'verify-email' },
      })
    } catch (error) {
      let message =
        authErrors[error.code] ||
        'เกิดข้อผิดพลาดในการตรวจสอบอีเมล์ โปรดลองอีกครั้ง'

      dispatch({
        type: actions.showPopupMessage,
        popupMessage: {
          type: 'danger',
          title: 'เกิดข้อผิดพลาด',
          message,
        },
      })
    }
  }, [user, code, dispatch])

  useEffect(() => {
    if (!code) {
      return navigate('/', { replace: true })
    }

    verifyEmail()
  }, [code, verifyEmail])

  return <VerifyEmail></VerifyEmail>
}

export default VerifyEmailPage
